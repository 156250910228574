import React, {useEffect, useState} from "react"
import {
    getDetailsRisqueEvaluationActivite,
    getDetailsRisqueEvaluationFraude, getDetailsRisqueEvaluationInformatique
} from "../../../../../data/risqueEvaluation";
import {getListeProbabilite} from "../../../../../data/params/probabilite";
import {getListeImpact} from "../../../../../data/params/impact";
import {getListeNiveau} from "../../../../../data/params/niveau";
import {Input, Space, Table, Tag} from "antd";
import {getListeRisqueTextBlanchiment} from "../../../../../data/risqueTextBlanchiment";
import TableTitle from "../../../../common/TableTitle";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {faIdCard, faTasks} from "@fortawesome/free-solid-svg-icons";
import {Identification} from "../../Identification";
import PageChildLabel from "../../../../common/PageLabel/PageChildLabel";
import FormLabel from "../../../../common/Form/FormLabel";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";

const SyntheseIdentification = ({
        mission,
        client,
        code,
        // modalId,
        prev
    }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'ED0');
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [isTaskSave, setIsTaskSave] = useState([]);

    const [risqueIncidences, setRisqueIncidences] = useState([]);
    const [risqueData, setRisqueData] = useState([]);
    const [probabilites, setProbabilites] = useState([]);
    const [impacts, setImpacts] = useState([]);
    const [niveaux, setNiveaux] = useState([]);
    const [datas, setDatas] = useState([]);
    const [conclusion, setConclusion] = useState("");
    const [auth, setAuth] = useState(false);

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, missionSelected, codeSommaire])

    const fetchProbabilite = async () => {
        try {
            setLoading(true);
            const res = await getListeProbabilite();
            setProbabilites(res.result);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
            setLoading(false);
        }
    };

    const fetchImpact = async () => {
        try {
            setLoading(true);
            const res = await getListeImpact();
            setImpacts(res.result);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
            setLoading(false);
        }
    };

    const fetchNiveaux = async () => {
        try {
            setLoading(true);
            const res = await getListeNiveau();
            setNiveaux(res.result);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProbabilite();
        fetchImpact();
        fetchNiveaux();
    }, []);

    useEffect(() => {
        if(activeTab === 1){
            getDetailsRisqueEvaluationActivite(missionSelected.id)
                .then((res) => {
                    if(res.result.length > 0){
                        setDatas(res.result)
                    }
                    else{
                        setDatas([])
                    }
                })
        }
        else if(activeTab === 2){
            getDetailsRisqueEvaluationFraude(missionSelected.id)
                .then((res) => {
                    if(res.result.length > 0){
                        setDatas(res.result)
                    }
                    else{
                        setDatas([])
                    }
                })

        }
        else if(activeTab === 3){
            getDetailsRisqueEvaluationInformatique(missionSelected.id)
                .then((res) => {
                    if(res.result.length > 0){
                        setDatas(res.result)
                    }
                    else{
                        setDatas([])
                    }
                })
        }
        else if(activeTab === 4){
            getListeRisqueTextBlanchiment(missionSelected.id)
                .then((res) => {
                    if(res.result){
                        const risque = res.result.find((item) => item.codeSommaire === "ED4")
                        if(risque) {
                            setRisqueData(risque.Lea_RisqueTexte_BlanchimentQuestions);
                            setConclusion(risque.conclusion);
                        }
                    }
                    else {
                        setRisqueData([]);
                        setConclusion("");
                    }
                })
        }
        else if(activeTab === 5){
            getListeRisqueTextBlanchiment(missionSelected.id)
                .then((res) => {
                    if(res.result){
                        const risque = res.result.find((item) => item.codeSommaire === 'ED5')
                        if(risque) {
                            setRisqueData(risque.Lea_RisqueTexte_BlanchimentQuestions);
                            setConclusion(risque.conclusion);
                        }
                    }
                    else {
                        setRisqueData([]);
                        setConclusion("");
                    }
                })
        }
    }, [missionSelected, activeTab]);

    useEffect(() => {
        if(datas.length > 0) {
            const arrayData = [];
            datas.forEach((event) => {
                event.Lea_RisqueIdentificationDetails?.forEach((detail) => {
                    const newSelectArray = {
                        id: detail.id,
                        incidence: detail.incidence,
                        poste: detail.Lea_RisqueIdentificationAssertions[0]?.poste,
                        assertions: detail.Lea_RisqueIdentificationAssertions[0]?.assertions,
                        probabiliteValue: detail.Lea_RisqueEvaluations[0]?.probabiliteValue || '',
                        probabilite_id: detail.Lea_RisqueEvaluations[0]?.probabilite_id || '',
                        impactValue: detail.Lea_RisqueEvaluations[0]?.impactValue || '',
                        impact_id: detail.Lea_RisqueEvaluations[0]?.impact_id || '',
                        score: detail.Lea_RisqueEvaluations[0]?.score || 0,
                        niveau_id: detail.Lea_RisqueEvaluations[0]?.niveau_id || '',
                    };
                    arrayData.push(newSelectArray);
                });
            });

            setRisqueIncidences(arrayData);
        }
        else {
            setRisqueIncidences([])
        }
    }, [datas])

    const columns = [
        {
            title: () => <TableTitle>Incidence (effet) du facteur de risque sur les EF</TableTitle>,
            dataIndex: 'incidence',
            width: 300,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.incidence}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Probabilité de survenance</TableTitle>,
            dataIndex: 'probabilite',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {probabilites.find((probabilite) => probabilite.id === record.probabilite_id)?.libelle}
                    </span>
                </Tag>
            ),
        },

        {
            title: () => <TableTitle>Impact financier</TableTitle>,
            dataIndex: 'impactFinancier',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {impacts.find((impact) => impact.id === record.impact_id)?.libelle}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Score combiné</TableTitle>,
            dataIndex: 'score',
            width: 50,
            render: (_, record) => (
                <Tag>
                    <span className="fw-bolder fs-6" style={{fontFamily: "Oswald, sans-serif"}}>{record.score}</span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Niveau Risque</TableTitle>,
            dataIndex: 'niveau_id',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {niveaux.find((niveau) => niveau.id === record.niveau_id)?.libelle}
                    </span>
                </Tag>
            ),
        },
    ];

    const columnsBlanchiment = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 70,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Travaux à faire (TAF)</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        value={record.libelle}
                        readOnly={true}
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>ASSERTIONS</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Réponses</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
               <div className="fs-5 fw-bolder">
                   {record.reponse === '1' ? 'N/A' : record.reponse === '2' ? 'OUI' : 'NON'}
               </div>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 450,
            render: (_, record) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        value={record.commentaire}
                        readOnly={true}
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                    />
                </div>
            ),
        },
    ];

    const RisqueIdentificationComponent = ({title}) => {
        return (
            <>
                <div className="mx-5 mb-15">
                    <PageChildLabel label={title} />
                    <div className="table-responsive my-10">
                        <Table
                            rowClassName={() =>
                                `cursor-pointer h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columns}
                            rowKey={(record, index) => index}
                            dataSource={risqueIncidences ?? []}
                            pagination={false}
                            scroll={{y: 800}}
                            loading={loading}
                            rowSelection
                        />
                    </div>
                </div>
            </>
        )
    }

    const RisqueBlanchimentComponent = ({title}) => {
        return (
            <>
                <div className="mx-5 mb-15">
                    <PageChildLabel label={title} />
                    <div className="table-responsive my-10">
                        <Table
                            rowClassName={() =>
                                `cursor-pointer h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columnsBlanchiment}
                            rowKey={(record, index) => index}
                            dataSource={risqueData ?? []}
                            pagination={false}
                            scroll={{y: 800}}
                            loading={loading}
                            rowSelection
                        />
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex flex-column mb-3 mx-2">
                        <FormLabel label="CONCLUSION" />
                        <Input.TextArea
                            style={{fontSize: "14px"}}
                            size="large"
                            bordered={false}
                            value={conclusion}
                            autoSize={{
                                minRows: 4,
                                maxRows: 12,
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <Identification
                    identifications={identifications}
                    mission={missionSelected}
                />
            ),
        },
        {
            code: "ED1",
            title: `ED1: Risques liés à l’activité - ${selectYear}`,
            description: `ED1: Risques liés à l’activité - ${selectYear}`,
            icon: faTasks,
            content: (
                <RisqueIdentificationComponent
                    title={"ED1: Risques liés à l’activité"}
                />
            ),
        },
        {
            code: "ED2",
            title: `ED2: Risques liés à la fraude et aux erreurs - ${selectYear}`,
            description: `ED2: Risques liés à la fraude et aux erreurs - ${selectYear}`,
            icon: faTasks,
            content: (
                <RisqueIdentificationComponent
                    title={"ED2: Risques liés à la fraude et aux erreurs"}
                />
            ),
        },
        {
            code: "ED3",
            title: `ED3: Risques liés à l’informatique - ${selectYear}`,
            description: `ED3: Risques liés à l’informatique - ${selectYear}`,
            icon: faTasks,
            content: (
                <RisqueIdentificationComponent
                    title={"ED3: Risques liés à l’informatique"}
                />
            ),
        },
        {
            code: "ED4",
            title: `ED4: Risques liés au blanchiment d’argent - ${selectYear}`,
            description: `ED4: Risques liés au blanchiment d’argent - ${selectYear}`,
            icon: faTasks,
            content: (
                <RisqueBlanchimentComponent
                    title={"ED4: Risques liés au blanchiment d’argent"}
                />
            ),
        },
        {
            code: "ED5",
            title: `ED5: Risques liés au respect des textes légaux et réglementaires - ${selectYear}`,
            description: `ED5: Risques liés au respect des textes légaux et réglementaires - ${selectYear}`,
            icon: faTasks,
            content: (
                <RisqueBlanchimentComponent
                    title={"ED5: Risques liés au respect des textes légaux et réglementaires"}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"ED0 – Synthèse des risques identifiés et évalués"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={false}
                    isLandingScape={false}
                    printTitle={(`ED0 – Synthèse des risques identifiés et évalués ${selectYear}`)}
                    excelData={[]}
                    printContent={null}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
}

export {SyntheseIdentification}